import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import temple from '../imgs/temple.png'
import chat from '../imgs/chat.png'
import chat2 from '../imgs/chat2.png'
import user from '../imgs/user.png'
import logs from '../imgs/logs2.png'
import coin from '../imgs/coin.png'
import wallet from '../imgs/wallet.png'
import crystal from '../imgs/crystal.png'
import home from '../imgs/home.png'
import home2 from '../imgs/home2.png'

let Nav = () => {
  let dispatch = useDispatch()
  let screenWidth = useSelector(state => state.screenWidth)
  let screenHeight = useSelector(state => state.screenHeight)
  let device = useSelector(state => state.device)
  let user = useSelector(state => state.user)
  let tab = useSelector(state => state.tab)

  let isPwa = device?.includes('PWA')
  if (process.env.NODE_ENV === 'development') isPwa = true

  if (!isPwa || !user?.state) return null
  //no wallet
  if (!(user?.key && user?.solo) && !(user?.keySol && user?.soloSol)) return null

  let setTab = tab => {
    dispatch({type: 'set', payload: {tab, showSideBar: false}})
  }

  let barLeft = 1000 //offscreen
  if (tab === 'wallet') {
    barLeft = 12
  }
  if (tab === 'home') {
    barLeft = 91
  }
  if (tab === 'chat') {
    barLeft = 172
  }

  let clickChat = () => {
    setTab('chat')
  }

  let fullScreenIos = device?.includes('iOS') && screenHeight > 700
  return (
    <div
      className={' noselect flexCol'}
      style={{
        width: screenWidth,
        height: fullScreenIos ? 74 : 60,
        backgroundColor: '#ddd',
        position: 'fixed',
        bottom: 0,
        alignSelf: 'stretch',
        alignItems: 'center',
      }}>
      <div style={{width: 240}}>
        <div
          className={'navBar'}
          style={{
            backgroundColor: '#000',
            height: 2,
            width: 58,
            transform: `translateX(${barLeft}px)`,
          }}
        />
      </div>
      <div className={'flexRow'} style={{marginTop: 10, width: 240}}>
        <div onClick={() => setTab('wallet')} className={'flexCenter'} style={{height: 36, width: 80}}>
          <img
            className={'navBarOp'}
            src={wallet}
            style={{
              height: 33,
              width: 34,
              opacity: tab == 'wallet' ? 0.74 : 0.5,
              marginBottom: 2,
            }}
          />
        </div>
        <div onClick={() => setTab('home')} className={'flexCenter'} style={{height: 36, width: 80}}>
          <img
            className={'navBarOp'}
            src={home2}
            style={{
              height: 37,
              widht: 37,
              opacity: tab == 'home' ? 0.74 : 0.5,
            }}
          />
        </div>
        <div onClick={() => clickChat()} className={'flexCenter'} style={{height: 36, width: 80}}>
          <img
            className={'navBarOp'}
            src={false ? chat : chat2}
            style={{
              height: 33,
              widht: 34,
              opacity: tab == 'chat' ? 0.74 : 0.5,
            }}
          />
        </div>
      </div>
    </div>
  )
}
export default Nav

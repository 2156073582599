import React, {useState, useEffect} from 'react'
import Maps from './Maps'
import Axios from 'axios'

const Booklet = () => {
  const [showKanban, setShowKanban] = useState(() => {
    const saved = localStorage.getItem('showKanban')
    return saved ? JSON.parse(saved) : false
  })
  const [kanbanStep, setKanbanStep] = useState(0)
  const [activeProject, setActiveProject] = useState(null)
  const [kanbanColumns, setKanbanColumns] = useState(() => {
    const saved = localStorage.getItem('kanbanColumns')
    return saved
      ? JSON.parse(saved)
      : [
          {id: 1, name: 'Column 1', items: []},
          {id: 2, name: 'Column 2', items: []},
          {id: 3, name: 'Column 3', items: []},
          {id: 4, name: 'Column 4', items: []},
        ]
  })
  const [editingItem, setEditingItem] = useState(null)
  const [draggedItem, setDraggedItem] = useState(null)
  const [projects, setProjects] = useState(() => {
    const saved = localStorage.getItem('projects')
    return saved
      ? JSON.parse(saved)
      : Array(13)
          .fill(0)
          .map((_, i) => ({
            id: i + 1,
            name: `Project ${i + 1}`,
            columns: [],
          }))
  })

  const [darkMode, setDarkMode] = useState(() => {
    const saved = localStorage.getItem('darkMode')
    return saved ? JSON.parse(saved) : false
  })
  const [activeWeeks, setActiveWeeks] = useState([])
  const [activeDay, setActiveDay] = useState(() => {
    const saved = localStorage.getItem('activeDay')
    if (saved) {
      return parseInt(saved)
    }
    // Get today's date and calculate days since Jan 1, 2025
    const today = new Date()
    const start = new Date(2025, 0, 1)
    const diffTime = today.getTime() - start.getTime()
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
  })
  const [markerCounts, setMarkerCounts] = useState({})
  const [showNotes, setShowNotes] = useState(() => {
    const saved = localStorage.getItem('showNotes')
    return saved ? JSON.parse(saved) : false
  })
  const [generalNotes, setGeneralNotes] = useState(() => {
    const saved = localStorage.getItem('generalNotes')
    return saved || ''
  })
  const [savedNotes, setSavedNotes] = useState(() => {
    const saved = localStorage.getItem('savedNotes')
    const notes = saved ? JSON.parse(saved) : []
    // Sort by lastEdited timestamp, fallback to id (creation time) if no lastEdited
    return notes.sort((a, b) => (b.lastEdited || b.id) - (a.lastEdited || a.id))
  })
  const [activeNoteIndex, setActiveNoteIndex] = useState(null)
  const [activeMarker, setActiveMarker] = useState(null)
  const [showGoals, setShowGoals] = useState(false)
  const [weeklyGoals, setWeeklyGoals] = useState(() => {
    const saved = localStorage.getItem('weeklyGoals')
    return saved ? JSON.parse(saved) : {}
  })

  const [showUser, setShowUser] = useState(false)

  // Save weekly goals to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('weeklyGoals', JSON.stringify(weeklyGoals))
  }, [weeklyGoals])

  // Set initial active note on load
  useEffect(() => {
    if (savedNotes.length > 0 && activeNoteIndex === null) {
      setActiveNoteIndex(0)
      setGeneralNotes(savedNotes[0].content)
    }
  }, [savedNotes])

  // Save showNotes state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('showNotes', JSON.stringify(showNotes))
  }, [showNotes])

  // Save showKanban state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('showKanban', JSON.stringify(showKanban))
  }, [showKanban])

  // Save projects to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('projects', JSON.stringify(projects))
  }, [projects])

  // Save kanban columns to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('kanbanColumns', JSON.stringify(kanbanColumns))
  }, [kanbanColumns])

  const local = {
    page: {
      display: 'flex',
      flexDirection: 'row',
      height: '100vh',
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
    },
    sidebar: {
      width: 300,
      borderRight: `1px solid ${darkMode ? '#333' : '#eee'}`,
      overflowY: 'auto',
      padding: '2px',
      backgroundColor: darkMode ? '#222' : '#fff',
      color: darkMode ? '#fff' : '#000',
      position: 'relative',
    },
    calendar: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 2,
      width: '100%',
    },
    dateBox: {
      padding: '2px',
      border: `1px solid ${darkMode ? '#333' : '#eee'}`,
      borderRadius: '4px',
      backgroundColor: darkMode ? '#333' : '#fbfbfb',
      width: 'calc(14.28% - 2px)',
      aspectRatio: '1',
      boxSizing: 'border-box',
      textAlign: 'center',
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: darkMode ? '#fff' : '#000',
      cursor: 'pointer',
      position: 'relative',
    },
    markerCount: {
      position: 'absolute',
      top: 2,
      right: 2,
      backgroundColor: darkMode ? '#4a5568' : '#e2e8f0',
      borderRadius: '50%',
      width: 16,
      height: 16,
      fontSize: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    firstOfMonth: {
      backgroundColor: darkMode ? '#1a365d' : '#e6f3ff',
    },
    activeWeek: {
      backgroundColor: darkMode ? '#2a4365' : '#f8eae4',
    },
    activeDay: {
      border: `2px solid ${darkMode ? '#fff' : '#000'}`,
    },
    content: {
      flex: 1,
      backgroundColor: darkMode ? '#111' : '#fff',
    },
    controls: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '8px',
      alignItems: 'center',
    },
    weekDays: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
      padding: '8px 0',
      borderBottom: `1px solid ${darkMode ? '#333' : '#eee'}`,
      fontSize: 12,
    },
    notesToggle: {
      position: 'absolute',
      top: 6,
      right: -2,
      width: 40,
      height: 40,
      backgroundColor: darkMode ? '#333' : '#fff',
      border: `1px solid ${darkMode ? '#555' : '#ddd'}`,
      borderRadius: 3,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      cursor: 'pointer',
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    userToggle: {
      position: 'absolute',
      top: 52,
      right: -2,
      width: 40,
      height: 40,
      backgroundColor: darkMode ? '#333' : '#fff',
      border: `1px solid ${darkMode ? '#555' : '#ddd'}`,
      borderRadius: 3,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      cursor: 'pointer',
      zIndex: 1000,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    notesPanel: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: 'calc(90% - 300px)',
      height: '100%',
      backgroundColor: darkMode ? '#222' : '#fff',
      borderLeft: `1px solid ${darkMode ? '#333' : '#ddd'}`,
      transition: 'transform 0.3s ease-in-out',
      transform: showNotes ? 'translateX(0)' : 'translateX(100%)',
      zIndex: 999,
      padding: 2,
      boxSizing: 'border-box',
      display: 'flex',
    },
    goalsPanel: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 200,
      height: '100%',
      backgroundColor: darkMode ? '#222' : '#fff',
      borderLeft: `1px solid ${darkMode ? '#333' : '#ddd'}`,
      transition: 'transform 0.3s ease-in-out',
      transform: showGoals ? 'translateX(200px)' : 'translateX(100%)',
      zIndex: 998,
      padding: 2,
      boxSizing: 'border-box',
      overflowY: 'auto',
      paddingTop: 60,
    },
    weeklyGoal: {
      padding: 0,
      marginBottom: 1,
      backgroundColor: darkMode ? '#333' : '#f5f5f5',
      borderRadius: '4px',
    },
    goalInput: {
      width: '100%',
      padding: 0,
      backgroundColor: darkMode ? '#444' : '#fff',
      color: darkMode ? '#fff' : '#000',
      border: `1px solid ${darkMode ? '#555' : '#ddd'}`,
      borderRadius: '4px',
      fontSize: '14px',
      resize: 'none',
    },
    notesTextarea: {
      width: '98%',
      height: showKanban ? 'calc(60% - 20px)' : 'calc(100% - 30px)',
      backgroundColor: darkMode ? '#333' : '#fff',
      color: darkMode ? '#aaa' : '#000',
      border: `0px solid ${darkMode ? '#555' : '#ddd'}`,
      borderRadius: 2,
      padding: 10,
      resize: 'none',
      fontSize: 12,
      fontFamily: 'Lato, sans-serif',
      letterSpacing: 0.6,
      '::placeholder': {
        color: darkMode ? '#777' : '#333',
      },
    },
    notesEditor: {
      width: '100%',
      height: '100%',
      borderRight: `1px solid ${darkMode ? '#333' : '#ddd'}`,
      padding: 1,
      position: 'relative',
    },
    deleteButton: {
      position: 'absolute',
      top: 6,
      right: 6,
      padding: '4px 8px',
      backgroundColor: darkMode ? '#444' : '#e0e0e0',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      color: darkMode ? '#fff' : '#000',
      zIndex: 1,
    },
    kanbanButton: {
      position: 'absolute',
      bottom: 12,
      right: 6,
      padding: '4px 8px',
      backgroundColor: darkMode ? '#444' : '#e0e0e0',
      border: 'none',
      borderRadius: 2,
      cursor: 'pointer',
      color: darkMode ? '#fff' : '#000',
      zIndex: 3,
    },
    notesArchive: {
      width: 150,
      height: '100%',
      padding: 4,
      overflowY: 'auto',
    },
    archiveNote: {
      padding: '10px',
      marginBottom: '10px',
      backgroundColor: darkMode ? '#333' : '#f0f0f0',
      borderRadius: '4px',
      cursor: 'pointer',
      maxHeight: '100px',
      overflow: 'hidden',
    },
    archiveNoteActive: {
      backgroundColor: darkMode ? '#444' : '#e0e0e0',
      border: `1px solid ${darkMode ? '#555' : '#ccc'}`,
    },
    newNoteButton: {
      width: 95,
      padding: '2px',
      height: 40,
      marginBottom: '10px',
      backgroundColor: darkMode ? '#444' : '#e0e0e0',
      border: 'none',
      borderRadius: 3,
      cursor: 'pointer',
      color: darkMode ? '#fff' : '#000',
      fontSize: 12,
    },
    kanban: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: '100%',
      height: '40%',
      backgroundColor: darkMode ? '#222' : '#fff',
      borderLeft: `1px solid ${darkMode ? '#333' : '#ddd'}`,
      borderTop: `1px solid ${darkMode ? '#333' : '#ddd'}`,
      zIndex: 2,
    },
    kanbanColumn: {
      width: '25%',
      height: '100%',
      borderRight: `1px solid ${darkMode ? '#333' : '#ddd'}`,
      padding: '10px',
      position: 'relative',
    },
    addItemButton: {
      width: '100%',
      padding: '8px',
      backgroundColor: darkMode ? '#333' : '#eee',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      marginBottom: '8px',
    },
    itemTextarea: {
      width: '92%',
      height: '200px',
      padding: '8px',
      backgroundColor: darkMode ? '#444' : '#fff',
      color: darkMode ? '#fff' : '#000',
      border: `1px solid ${darkMode ? '#555' : '#ddd'}`,
      borderRadius: '4px',
      resize: 'none',
    },
    trashZone: {
      display: 'flex',
      height: '40px',
      backgroundColor: darkMode ? '#333' : '#eee',
      borderRadius: '4px',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      marginBottom: '8px',
      opacity: draggedItem ? 1 : 0.5,
    },
    userPanel: {
      position: 'absolute',
      top: 100,
      right: 0,
      width: 300,
      height: 200,
      backgroundColor: darkMode ? '#222' : '#fff',
      borderLeft: `1px solid ${darkMode ? '#333' : '#ddd'}`,
      transition: 'transform 0.3s ease-in-out',
      //transform: showUser ? 'translateX(0)' : 'translateX(100%)',
      zIndex: 999,
      padding: 2,
    },
  }

  const handleNewNote = () => {
    const newNote = {
      id: Date.now(),
      content: '',
      date: new Date().toLocaleDateString(),
      lastEdited: Date.now(),
    }
    const updatedNotes = [newNote, ...savedNotes]
    setSavedNotes(updatedNotes)
    setActiveNoteIndex(0)
    setGeneralNotes('')
    localStorage.setItem('savedNotes', JSON.stringify(updatedNotes))
  }

  const handleNotesChange = e => {
    const newNotes = e.target.value
    setGeneralNotes(newNotes)
    if (activeNoteIndex !== null) {
      const updatedNotes = [...savedNotes]
      updatedNotes[activeNoteIndex] = {
        ...updatedNotes[activeNoteIndex],
        content: newNotes,
        lastEdited: Date.now(),
      }
      // Re-sort notes by lastEdited
      const sortedNotes = updatedNotes.sort((a, b) => b.lastEdited - a.lastEdited)
      // Find new index of the active note after sorting
      const newActiveIndex = sortedNotes.findIndex(note => note.id === updatedNotes[activeNoteIndex].id)
      setSavedNotes(sortedNotes)
      setActiveNoteIndex(newActiveIndex)
      localStorage.setItem('savedNotes', JSON.stringify(sortedNotes))
    }
  }

  const handleDeleteNote = () => {
    if (activeNoteIndex !== null) {
      const updatedNotes = savedNotes.filter((_, index) => index !== activeNoteIndex)
      setSavedNotes(updatedNotes)
      localStorage.setItem('savedNotes', JSON.stringify(updatedNotes))

      if (updatedNotes.length > 0) {
        setActiveNoteIndex(0)
        setGeneralNotes(updatedNotes[0].content)
      } else {
        setActiveNoteIndex(null)
        setGeneralNotes('')
      }
    }
  }

  const handleArchiveNoteClick = index => {
    setActiveNoteIndex(index)
    setGeneralNotes(savedNotes[index].content)
  }

  const handleMarkerDelete = markerId => {
    // This function will be passed to Maps component
    setMarkerCounts(prev => {
      const newCounts = {...prev}
      if (newCounts[activeDay] > 0) {
        newCounts[activeDay]--
      }
      return newCounts
    })
  }

  const handleAddProject = () => {
    const newProject = {
      id: projects.length + 1,
      name: `Project ${projects.length + 1}`,
    }
    setProjects([...projects, newProject])
  }

  const handleProjectClick = project => {
    setActiveProject(project)
    setKanbanStep(1)
  }

  const handleAddItem = columnId => {
    setEditingItem({columnId, content: '', isNew: true})
  }

  const handleItemSave = (columnId, content, itemId = null) => {
    const newColumns = kanbanColumns.map(col => {
      if (col.id === columnId) {
        if (itemId) {
          // Editing existing item
          return {
            ...col,
            items: col.items
              .map(item => (item.id === itemId ? {...item, content, lastEdited: Date.now()} : item))
              .sort((a, b) => (b.lastEdited || 0) - (a.lastEdited || 0)),
          }
        } else {
          // Adding new item
          return {
            ...col,
            items: [{id: Date.now(), content, lastEdited: Date.now()}, ...col.items],
          }
        }
      }
      return col
    })
    setKanbanColumns(newColumns)
    setEditingItem(null)
  }

  const handleItemClick = (columnId, item) => {
    setEditingItem({columnId, content: item.content, id: item.id, isNew: false})
  }

  const handleDragStart = (columnId, item) => {
    setDraggedItem({columnId, item})
  }

  const handleDragOver = (e, columnId) => {
    e.preventDefault()
  }

  const handleDrop = (e, targetColumnId) => {
    e.preventDefault()
    if (!draggedItem) return

    const {columnId: sourceColumnId, item} = draggedItem

    if (sourceColumnId === targetColumnId) return

    // Remove item from source column and add to target column
    const newColumns = kanbanColumns.map(col => {
      if (col.id === sourceColumnId) {
        return {
          ...col,
          items: col.items.filter(i => i.id !== item.id),
        }
      }
      if (col.id === targetColumnId) {
        const updatedItem = {...item, lastEdited: Date.now()}
        return {
          ...col,
          items: [updatedItem, ...col.items],
        }
      }
      return col
    })

    setKanbanColumns(newColumns)
    setDraggedItem(null)
  }

  const handleDropOnTrash = e => {
    e.preventDefault()
    if (!draggedItem) return

    const {columnId: sourceColumnId, item} = draggedItem
    const newColumns = kanbanColumns.map(col => {
      if (col.id === sourceColumnId) {
        return {
          ...col,
          items: col.items.filter(i => i.id !== item.id),
        }
      }
      return col
    })

    setKanbanColumns(newColumns)
    setDraggedItem(null)
  }

  // Rest of the functions remain the same...
  const getDates = () => {
    const dates = []
    const startDate = new Date(2025, 0, 1)
    const endDate = new Date(2025, 11, 31)

    let currentDate = new Date(startDate)

    const firstDay = startDate.getDay()
    for (let i = firstDay; i > 0; i--) {
      const prevDate = new Date(startDate)
      prevDate.setDate(prevDate.getDate() - i)
      dates.push({
        date: prevDate.getDate(),
        month: prevDate.toLocaleDateString('en-US', {month: 'short'}),
        isFirstOfMonth: prevDate.getDate() === 1,
      })
    }

    while (currentDate <= endDate) {
      dates.push({
        date: currentDate.getDate(),
        month: currentDate.toLocaleDateString('en-US', {month: 'short'}),
        isFirstOfMonth: currentDate.getDate() === 1,
      })
      currentDate.setDate(currentDate.getDate() + 1)
    }

    const lastDay = endDate.getDay()
    for (let i = 1; i < 7 - lastDay; i++) {
      const nextDate = new Date(endDate)
      nextDate.setDate(nextDate.getDate() + i)
      dates.push({
        date: nextDate.getDate(),
        month: nextDate.toLocaleDateString('en-US', {month: 'short'}),
        isFirstOfMonth: nextDate.getDate() === 1,
      })
    }

    return dates
  }

  const handleDateClick = index => {
    const weekNumber = Math.floor(index / 7)

    if (activeDay === index) {
      setActiveWeeks(prevWeeks => {
        if (prevWeeks.includes(weekNumber)) {
          return prevWeeks.filter(week => week !== weekNumber)
        }
        return [...prevWeeks, weekNumber]
      })
    } else {
      setActiveDay(index)
      localStorage.setItem('activeDay', index.toString())
    }
  }

  const handleMarkerAdded = dayIndex => {
    setMarkerCounts(prev => ({
      ...prev,
      [dayIndex]: (prev[dayIndex] || 0) + 1,
    }))
  }

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode
    setDarkMode(newDarkMode)
    localStorage.setItem('darkMode', JSON.stringify(newDarkMode))
  }

  const handleMapClick = () => {
    if (showNotes) {
      setShowNotes(false)
    }
  }

  const handleWeeklyGoalChange = (weekNumber, value) => {
    setWeeklyGoals(prev => ({
      ...prev,
      [weekNumber]: value,
    }))
  }

  const login = username => {
    console.log('Login attempt:', username)
    // TODO: Implement actual login logic
    Axios.post('/login', {username})
      .then(res => {
        console.log('Login response:', res.data)
      })
      .catch(err => {
        console.log('Login error:', err)
      })
  }

  let placeholderQuotes = [
    "you miss 100% of shots you don't take",
    'the only way to do great work is to love what you do',
    'the best way to predict the future is to invent it',
    'success is not final, failure is not fatal',
    'whether you think you can or think you cannot, you are right',
    'the journey of a thousand miles begins with one step',
    'the harder you work, the luckier you get',
    'done is better than perfect',
    'stay hungry, stay foolish',
    'the best time to plant a tree was 20 years ago, the second best time is now',
  ]

  return (
    <div style={local.page}>
      <div style={local.sidebar}>
        <div style={local.controls}>
          <button onClick={toggleDarkMode}>{darkMode ? '☀️' : '🌙'}</button>
          <button onClick={() => setShowGoals(!showGoals)}>{darkMode ? '📃' : '📄'}</button>
          <button>⚙️</button>
        </div>
        <div style={local.weekDays}>
          <span>Sun</span>
          <span>Mon</span>
          <span>Tue</span>
          <span>Wed</span>
          <span>Thu</span>
          <span>Fri</span>
          <span>Sat</span>
        </div>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div style={local.calendar}>
            {getDates().map((dateObj, i) => {
              const weekNumber = Math.floor(i / 7)
              const isActiveWeek = activeWeeks.includes(weekNumber)
              const isActiveDay = activeDay === i
              const markerCount = markerCounts[i]
              return (
                <div
                  key={i}
                  style={{
                    ...local.dateBox,
                    ...(isActiveWeek && !dateObj.isFirstOfMonth ? local.activeWeek : {}),
                    ...(dateObj.isFirstOfMonth ? local.firstOfMonth : {}),
                    ...(isActiveDay ? local.activeDay : {}),
                  }}
                  onClick={() => handleDateClick(i)}>
                  {dateObj.isFirstOfMonth ? `${dateObj.month} ${dateObj.date}` : dateObj.date}
                  {markerCount > 0 && <div style={local.markerCount}>{markerCount}</div>}
                </div>
              )
            })}
          </div>
          {false && (
            <div style={local.goalsPanel}>
              {getDates().map((dateObj, i) => {
                const weekNumber = Math.floor(i / 7)
                // Only show one goal input per week (on Sunday)
                if (i % 7 === 0) {
                  return (
                    <div key={weekNumber} style={local.weeklyGoal}>
                      <textarea
                        style={local.goalInput}
                        value={weeklyGoals[weekNumber] || ''}
                        onChange={e => handleWeeklyGoalChange(weekNumber, e.target.value)}
                        placeholder=""
                        rows={3}
                      />
                    </div>
                  )
                }
                return null
              })}
            </div>
          )}
        </div>
      </div>
      <div style={local.content} onClick={handleMapClick}>
        <div style={{height: '100%'}}>
          <Maps
            darkMode={darkMode}
            activeWeeks={activeWeeks}
            activeDay={activeDay}
            onMarkerAdded={handleMarkerAdded}
            handleMarkerDelete={handleMarkerDelete}
            activeMarker={activeMarker}
            setActiveMarker={setActiveMarker}
          />
        </div>
      </div>

      <button style={local.notesToggle} onClick={() => setShowNotes(!showNotes)}>
        <i className="fas fa-pencil-alt" style={{color: darkMode ? 'white' : 'black'}}></i>
      </button>
      {!showNotes && (
        <button style={local.userToggle} onClick={() => setShowUser(!showUser)}>
          <i className="fas fa-user" style={{color: darkMode ? 'white' : 'black'}}></i>
        </button>
      )}
      {showUser && (
        <div style={local.userPanel}>
          <input
            autoFocus
            type="text"
            onKeyDown={e => {
              if (e.key === 'Enter') {
                login(e.target.value)
              }
            }}
            style={{
              width: '100%',
              padding: '8px',
              backgroundColor: darkMode ? '#444' : '#fff',
              color: darkMode ? '#fff' : '#000',
              border: `1px solid ${darkMode ? '#555' : '#ddd'}`,
              borderRadius: '4px',
              fontSize: '14px',
            }}
          />
        </div>
      )}
      <div style={local.notesPanel}>
        <div style={local.notesEditor}>
          {activeNoteIndex !== null && (
            <button style={local.deleteButton} onClick={handleDeleteNote}>
              <i style={{opacity: 0.4}} className="fa fa-trash"></i>
            </button>
          )}
          {activeNoteIndex !== null && (
            <button style={local.kanbanButton} onClick={() => setShowKanban(!showKanban)}>
              <i style={{opacity: 0.4}} className="fa fa-th"></i>
            </button>
          )}
          <textarea
            value={generalNotes}
            onChange={handleNotesChange}
            style={local.notesTextarea}
            placeholder={`.....${placeholderQuotes[Math.floor(Math.random() * placeholderQuotes.length)]}`}
            spellCheck="false"
          />
          {showKanban && (
            <div style={local.kanban}>
              {kanbanStep === 0 ? (
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 5,
                    padding: '10px',
                    overflowY: 'auto',
                    height: '100%',
                    alignContent: 'flex-start',
                  }}>
                  {projects.map(project => (
                    <div
                      key={project.id}
                      onClick={() => handleProjectClick(project)}
                      style={{
                        width: '80px',
                        height: '80px',
                        backgroundColor: darkMode ? '#333' : '#eee',
                        borderRadius: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}>
                      {project.name}
                    </div>
                  ))}
                  <div
                    onClick={handleAddProject}
                    style={{
                      width: '80px',
                      height: '80px',
                      backgroundColor: darkMode ? '#444' : '#ddd',
                      borderRadius: 3,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      fontSize: '24px',
                    }}>
                    +
                  </div>
                </div>
              ) : (
                <div style={{display: 'flex', height: '100%'}}>
                  {kanbanColumns.map((column, index) => (
                    <div
                      key={column.id}
                      style={local.kanbanColumn}
                      onDragOver={e => handleDragOver(e, column.id)}
                      onDrop={e => handleDrop(e, column.id)}>
                      {index === 0 ? (
                        <h3 style={{margin: '0 0 10px 0'}}>{column.name}</h3>
                      ) : (
                        draggedItem && (
                          <div style={local.trashZone} onDragOver={e => e.preventDefault()} onDrop={handleDropOnTrash}>
                            <i className="fa fa-trash" style={{opacity: 0.6}}></i>
                          </div>
                        )
                      )}
                      {index === 0 && (
                        <button style={local.addItemButton} onClick={() => handleAddItem(column.id)}>
                          +
                        </button>
                      )}
                      {editingItem?.columnId === column.id && editingItem?.isNew && (
                        <textarea
                          autoFocus
                          style={local.itemTextarea}
                          value={editingItem.content}
                          onChange={e => setEditingItem({...editingItem, content: e.target.value})}
                          onBlur={() => handleItemSave(column.id, editingItem.content)}
                        />
                      )}
                      {column.items.map(item =>
                        editingItem?.id === item.id && editingItem?.columnId === column.id ? (
                          <textarea
                            key={item.id}
                            autoFocus
                            style={local.itemTextarea}
                            value={editingItem.content}
                            onChange={e => setEditingItem({...editingItem, content: e.target.value})}
                            onBlur={() => handleItemSave(column.id, editingItem.content, item.id)}
                          />
                        ) : (
                          <div
                            key={item.id}
                            onClick={() => handleItemClick(column.id, item)}
                            draggable
                            onDragStart={() => handleDragStart(column.id, item)}
                            style={{
                              padding: '8px',
                              backgroundColor: darkMode ? '#333' : '#f5f5f5',
                              marginBottom: '8px',
                              borderRadius: '4px',
                              cursor: 'pointer',
                            }}>
                            {item.content}
                          </div>
                        ),
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        <div style={local.notesArchive}>
          <button style={local.newNoteButton} onClick={handleNewNote}>
            + Note
          </button>
          {savedNotes.map((note, index) => (
            <div
              key={note.id}
              style={{
                ...local.archiveNote,
                ...(activeNoteIndex === index ? local.archiveNoteActive : {}),
              }}
              onClick={() => handleArchiveNoteClick(index)}>
              <div style={{fontSize: '12px', marginBottom: '5px'}}>{note.date}</div>
              <div style={{fontSize: '14px'}}>{note.content.substring(0, 100)}...</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Booklet

import React, {useState, useEffect} from 'react'
import ethLogo from '../imgs/ethLogo.png'
import {images} from './imgArr'

export default function Main() {
  const gap = 4.5
  let local = {
    ok: {},
  }

  let arr = new Array(5000).fill(0)
  let showArr = arr?.map((a, k) => {
    const imgSrc = `/pfp/${images[k % images.length]}`
    return (
      <div key={k} className="box" style={local.box}>
        <img src={imgSrc} className="logoSmall" alt="LogoSmall" />
        <div className="boxGrows">
          <img src={imgSrc} className="logo" alt="Logo" />
        </div>
      </div>
    )
  })
  return (
    <div style={{paddingTop: 6}}>
      <div
        style={{
          display: 'flex',
          height: '100vh',
          backgroundColor: '#fbf7f5',
          alignSelf: 'stretch',
          justifyContent: 'center',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: gap,
          // position: 'relative',
        }}>
        {showArr}
      </div>
    </div>
  )
}

import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import xWhite from '../imgs/xWhite.png'

let Modal = () => {
  let dispatch = useDispatch()
  let isMobile = useSelector(state => state.isMobile)
  let screenWidth = useSelector(state => state.screenWidth)
  let modalShow = useSelector(state => state.modalShow)

  let modalHeight = 400
  let heightCalc = 'calc(100vh - 400px)'
  if (isMobile) {
    heightCalc = 'calc(100vh - 240px)'
    modalHeight = 240
  }
  console.log('modalShow', modalShow)

  let bigImgSize = isMobile ? screenWidth - 40 : 400

  let step = 0

  if (modalShow == 'sending') {
    step = 1
  } else if (modalShow == 'mining') {
    step = 2
  }

  let insideMenu = (
    <div
      className={'flexCol'}
      style={{
        alignItems: 'center',
        paddingTop: 30,
        color: '#fff',
        fontSize: 18,
      }}>
      <div className={'flexRow'}>
        <div style={{fontSize: 16, marginRight: 5, marginTop: 2}}>{`(${step}/2)`}</div>
        <div>{`${modalShow}...`}</div>
      </div>
      <div className={'arc-hider'} style={{marginTop: 50}}></div>
      {modalShow == 'sending' && (
        <div
          onClick={() => dispatch({type: 'set', payload: {modelShow: false}})}
          className={'flexCenter'}
          style={{
            border: '1px solid #fff',
            borderRadius: 6,
            height: 46,
            width: 240,
            marginTop: 100,
          }}>
          cancel
        </div>
      )}
    </div>
  )

  let menu = (
    <div
      className={'flexCol lato300'}
      style={{
        alignItems: 'center',
        backgroundColor: '#666',
        width: screenWidth,
        height: modalHeight,
        border: '0px solid #444',
        borderTopWidth: 1.5,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      }}>
      {insideMenu}
    </div>
  )

  return (
    <div
      className={'lato300 flexCol noselect'}
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        display: 'flex',
        flex: 1,
        height: '100vh',
        transform: modalShow ? 'translateY(0)' : `translateY(100vh)`,
        transition: 'transform 450ms ease',
        width: screenWidth,
        zIndex: 100, //modalShow ? 150 : -2,
      }}>
      <div
        onClick={() => {
          console.log('x')
          dispatch({type: 'set', payload: {modalShow: false}})
        }}
        style={{
          width: screenWidth,
          height: heightCalc,
        }}
      />
      {menu}
    </div>
  )
}
export default Modal

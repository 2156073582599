import {ethers} from 'ethers'
import Axios from 'axios'
import {deviceCheck, browserCheck} from '../util/helpers'
import abi from '../util/Soco.json'

//images
import xPng from '../imgs/x.png'
import ethLogo from '../imgs/ethLogo.png'
import sol1 from '../imgs/sol1.png'
import leftPng from '../imgs/left.png'
import copyPng from '../imgs/copy.png'
import qrPng from '../imgs/qr.png'
import maticPng from '../imgs/matic2.png'

export const getDevice = () => {
  return async (dispatch, getState) => {
    let device = deviceCheck()
    let browser = ''
    if (device?.includes('Android')) {
      browser = browserCheck('android')
    }
    if (device?.includes('iOS')) {
      browser = browserCheck('ios')
    }

    dispatch({
      type: 'set',
      payload: {device, browser},
    })
  }
}

export const getMe = () => {
  return async (dispatch, getState) => {
    let {user, url} = getState()
    dispatch({type: 'set', payload: {loading: true}})
    try {
      let res = await Axios.get(`${url}/user`)
      console.log('get user', res)
      user = {...user, ...res.data?.user}
      dispatch({type: 'set', payload: {user}})
    } catch (err) {
      console.log('err getUser', err)
      dispatch({type: 'set', payload: {loadingSol: false, loadingEth: false}})
    }
  }
}

export const getContract = () => {
  return async (dispatch, getState) => {
    let {network, contractAddress} = getState()
    try {
      let contractABI = abi.abi
      const solContract = new ethers.Contract(contractAddress, contractABI)
      dispatch({
        type: 'set',
        payload: {solContract},
      })
    } catch (err) {
      console.log('getContraxt err', err)
    }
  }
}

export const preloadImg = () => {
  return async (dispatch, getState) => {
    const solImg = new Image()
    solImg.src = sol1
    const ethImg = new Image()
    ethImg.src = ethLogo
    const xImg = new Image()
    xImg.src = xPng
    const leftImg = new Image()
    leftImg.src = leftPng
    const copyImg = new Image()
    copyImg.src = copyPng
    const qrImg = new Image()
    qrImg.src = qrPng
    const maticImg = new Image()
    maticImg.src = maticPng
  }
}

//eth
export const getMeEth = payload => {
  return async (dispatch, getState) => {
    let {wallet, url} = getState()
    console.log('getMeEth')
    if (!payload) payload = {wallet}
    try {
      const addrArr = [payload?.wallet?.obj?.address]
      let res = await Axios.post(`${url}/txGetUsersEth`, {addrArr})
      let meArr = res?.data?.users[0]
      let meEth = {
        address: meArr[0],
        info: meArr[1],
        groups: meArr[4],
      }
      let loadingEth = false
      if (meEth?.groups?.length > 0) {
        await dispatch(getGroupsEth(meEth?.groups))
      }
      dispatch({type: 'set', payload: {meEth, loadingEth: false}})
    } catch (err) {
      dispatch({type: 'set', payload: {loadingEth: false}})
    }
  }
}

export const getGroupsEth = groupsArr => {
  return async (dispatch, getState) => {
    let {url} = getState()
    //if (!payload) payload = {wallet}
    const promises = groupsArr.map(obj => {
      const bigNum = ethers.BigNumber.from(obj.hex)
      const groupNum = bigNum.toNumber()
      return Axios.post(`${url}/txGetGroupEth`, {groupNum})
    })

    let groupsEth = await Promise.all(promises)
    groupsEth = groupsEth.map(a => {
      let group = a?.data?.group
      let id = ethers.BigNumber.from(group[2]?.hex)
      id = id.toNumber()
      return {
        owner: group[0],
        name: group[1],
        id,
        members: group[3],
        bets: group[4],
        type: 'eth',
      }
    })
    console.log('groupsEth', groupsEth)
    dispatch({type: 'set', payload: {groupsEth}})
  }
}

export const setEnv = (isMobile, screenWidth, screenHeight) => ({
  type: 'set',
  payload: {
    isMobile,
    screenWidth,
    screenHeight,
  },
})

export const setPush = pushKey => ({
  type: 'set',
  payload: {
    pushKey,
  },
})
